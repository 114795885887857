<template>
    <div class="navbar-right">
        <div class="search-indicator"></div>
        <div class="menu-block">
            <div id="make" class="make">
                <div class="heading">
                    <i class="menu-icon fal fa-car"></i>
                    <h1 class="menu-heading">Make</h1>
                </div>
                <p class="selected-make" v-model="selected_make">{{ selected_make }}</p>
                <div class="dropdown">
                    <i class="fal fa-chevron-down"></i>
                </div>
                <div class="make-dropdown">
                    <ul class="wooc_sclist">
                        <li v-on:click="select_make(sc.name, sc.term_id)" v-for="sc in make" v-if="make">{{ sc.name }}</li>
                    </ul>
                </div>
            </div>
            <div id="model" class="model">
                <div class="heading">
                    <i class="menu-icon fal fa-cubes"></i>
                    <h1 class="menu-heading">Model</h1>
                </div>
                <div class="dropdown">
                    <i class="fal fa-chevron-down"></i>
                </div>

                <div class="model-dropdown">
                    <ul v-model="selected_model_objs" v-if="selected_model_objs.length > 0">
                        <li v-for="model in selected_model_objs"><a :href="model.link">{{ model.name }}</a></li>
                    </ul>
                    <ul v-else-if="selected_make_id == 0">
                        <li class="msg">Please select a Make of Vehicle</li>
                    </ul>
                    <ul v-else-if="loading">
                        <li class="msg"><i class="far fa-tire fa-spin fa-3x"></i> Loading...</li>
                    </ul>
                    <ul v-else>
                        <li class="msg">No models available for this make.</li>
                    </ul>
                </div>
            </div>
            <div id="products" class="products">
                <div class="heading">
                    <i class="menu-icon fas fa-box"></i>
                    <h1 class="menu-heading">Products</h1>
                </div>
                <div class="dropdown">
                    <i class="fal fa-chevron-down"></i>
                </div>

                <div class="model-dropdown-e">
                    <ul class="prod_list">
                        <li v-for="pc in product_name"><a :href="pc.link" v-html="pc.name">{{ pc.name }}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: ["make_name", "model_name", "selected_make", "product_name"],
        data: function () {
            return {
                selected_make_id: 0,
                selected_model_objs: [],
                loading: false
            }
        },
        computed: {
            make: function() {
                return sortBy(this.make_name, [function(o) { return o.name }]);
            }
        },
        methods: {
            select_make: function (make, id) {
                var element = document.getElementById('make');
                element.className += " " + 'populate';
                this.selected_make = make;
                this.selected_make_id = id;
                this.loadModels(this.selected_make)

            },
            loadModels: function (make) {
                var self = this;
                this.loading = true;
                axios.defaults.responseType = 'json';
                axios.get('/wp-json/models/v1/make/' + this.selected_make_id)
                    .then(function (response) {
                        response.data.sort(OrderListBy('name'));
                        self.selected_model_objs = response.data;
                        self.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        }

    }

    /**
     * Sortin JSON resposne
     *
     * @param prop
     * @returns {Function}
     * @constructor
     */
    function OrderListBy(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            }
            else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }
</script>

<style scoped>

</style>
