$(document).ready(function () {

    $('#wp-admin-bar-fw-package a').on('click', function (e) {
        e.preventDefault();
        $.ajax({
            url: '/wp-json/development/package',
            type: 'post',
            headers: {
                "X-WP-Nonce": window.wprest.nonce,
            },
            dataType: 'json',
            success: function () {

            },
            fail: function () {

            },
        })
    })

    $('#wp-admin-bar-fw-recompile-scss a').on('click', function (e) {
        // stop button from going somewhere
        e.preventDefault();

        // call wp-json endpoint to compile scss
        $.ajax({
            url: '/wp-json/development/compile-scss',
            type: 'post',
            headers: {
                "X-WP-Nonce": window.wprest.nonce,
            },
            dataType: 'json',
            success: function () {
                let queryString = '?reload=' + new Date().getTime();
                $('link[rel="stylesheet"]').each(function () {
                    this.href = this.href.replace(/\?.*|$/, queryString);
                });
            },
            fail: function () {

            },
        });
    })
})
