jQuery(document).ready(function ($) {

  // Variation price change
  $('select').click(function () {
    if ('' !== $('input.variation_id').val() && $('input.variation_id').val() !== 0) {
      $('p.price').html($('div.woocommerce-variation-price > span.price').html());
      console.log('Variation ID: ' + $('input.variation_id').val());
    } else {
      $('p.price').html($('div.hidden-variable-price').html());
      if ($('p.availability'))
        $('p.availability').remove();
      console.log('NULL');
    }
  });

  $( 'form.checkout' ).on( 'change', 'input[name^="payment_method"]', function() {
    console.log('Update checkout');
    $('body').trigger('update_checkout');
  });

  // $( ".single_variation_wrap" ).on( "show_variation", function ( event, variation ) {
  //   alert( variation.variation_id );
  //   console.log( variation );
  // });
  //
  // $( document ).on( "found_variation.first", function ( e, v ) {
  //   alert( v.variation_id );
  //   console.log( v );
  // });
});
