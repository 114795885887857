jQuery(document).ready(function ($) {
    /**
     * Mobile Navigation Slider Menu
     */
    $('.slide-toggle').on('click', function () {
        var sliderNav = $('#sliderNav');
        sliderNav.css('margin-left') === '-300px' ? sliderNav.css('margin-left', '0') : sliderNav.css('margin-left', '-300px');
    });

    $('.card-header').on('click', function () {
        if ($(this).find('.accordion-toggle').hasClass('collapsed')) {
            $(this).css('padding', '20px 30px 20px 40px');
            $(this).parent().css('background-color', '#F8F8F8');
            $(this).css('background-color', '#f8f8f8');
        }
        else {
            $(this).css('padding', '20px 30px 20px 30px');
            $(this).parent().css('background-color', '#fff');
            $(this).css('background-color', '#fff');
        }
    });

    /**
     * Header Bootstrap Drop-down menu hacks
     */
    $('.navbar .dropdowslide-togglen').hover(function() {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(250).slideDown();
    }, function() {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp();
    });
    $('.navbar .dropdown > a').click(function() {
        if ($(this).next('.dropdown-menu').first().is(':visible')) {
            location.href = this.href;
        } else {
            $(this).next('.dropdown-menu').first().slideDown();
        }
    });

    /**
     * Quick Product Search Make/Model/Product Selector
     */
    $('#makeSelect').change(function () {
        var makeSelectValueID = $(this).find(":selected").data('id');

        if (makeSelectValueID !== undefined) {
          $.ajax({
            url: '/wp-json/models/v1/make/' + makeSelectValueID,
            type: 'GET',
            dataType: 'json',
            data: makeSelectValueID,
            success: function (response) {
              if (response.length === 0) {
                $('#modelSelect').children('option').remove();
                $('#modelSelect').append('<option value="nomodels">No Models available for this Make</option>');
              }
              else {
                response.sort(OrderListBy('slug'));
                $('#modelSelect').children('option').remove();
                $('#modelSelect').append('<option value="heading">Select a model</option>');
                for (var i = 0; i < response.length; i++) {
                  $('#modelSelect').append('<option data-link="' + response[i].link + '" value="' + response[i].term_id + '">' + response[i].cat_name + '</option>');
                }
              }
            },
          });
        } else {
          $('#modelSelect').children('option').remove();
          $('#modelSelect').append('<option value="heading">Please Select a Make first</option>');
        }
    });

    // make drop down selected
    $('.make-dropdown > .wooc_sclist > li ').on('click', function () {
        $(this).siblings().removeClass('selected');
        $(this).addClass('selected');

        $('.make-dropdown > .wooc_sclist').hide();
        $('.model-dropdown').show();
    });

    // Make on hover show
    $('#make').on('click', function () {
      if (!$(this).hasClass('populate')) {
        $('.model-dropdown').hide();
      }
      $('.model-dropdown-e').hide();
      $('.make-dropdown').fadeIn();
    });

    $('#model').on('click', function () {
      $('.model-dropdown-e').hide();
      $('.make-dropdown').hide();
      $('.model-dropdown').fadeIn();
    });

    $('#products').on('click', function () {
      $('.model-dropdown').hide();
      $('.make-dropdown').hide();
      $('.model-dropdown-e').fadeIn();
    });

    /**
     * Homepage Quick Product Search
     */
    $('#modelSelect').change(function () {
      var datalink = $(this).find(':selected').attr('data-link');

      if (datalink) {
        window.location.href = datalink;
      }
    });

    $('#make-model-form').on('submit', function (e) {
        e.preventDefault();
        // get data-link and redirect
        var datalink = $('#modelSelect').find(':selected').attr('data-link');

        if (datalink) {
            window.location.href = datalink;
        } else if ($('#category-search').val() != 'heading') {
            var catlink = $('#category-search').find(':selected').attr('data-link');
            window.location.href = catlink;
        } else {
            alert('No models available for this make');
        }
    });

    // Redirect to category page on option select
    $('#category-search').on('change', function () {
        if ($('#category-search').val() != 'heading') {
            var catlink = $('#category-search').find(':selected').attr('data-link');
            window.location.href = catlink;
        }
    });

    // smooth scroll to What we do section
    // $('.menu-item-130 > a').on('click', function () {
    //     console.log('scroll to called');
    //     $([document.documentElement, document.body]).animate({
    //         scrollTop: $('#whatwedo').offset().top - 125
    //     }, 700);
    // });

    // popup search modal
    $('#mobile_search').on('click', function () {
        $('#searchModal').addClass('open');
        $('#searchModal > form > input[type="search"]').focus();
    });

    $('#searchModal, #search button.close').on('click keyup', function (event) {
        if (event.target == this || event.target.className == 'close' || event.keyCode == 27) {
            $(this).removeClass('open');
        }
    });

    /**
     * Single Product Page
     */
    // default show first tab on page load
    $('.module-tabbed_feature_list .nav-link:first').addClass('active');
    $('.module-tabbed_feature_list .tab-content .tab-pane:first').addClass('active show');

    /**
     * Responsive Embedded YouTube Videos JS Style
     * Source: https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
     */
        // Find all YouTube videos
    var $allVideos = $("iframe[src^='//www.youtube.com']"),

        // The element that is fluid width
        $fluidEl = $("body");

        // Figure out and save aspect ratio for each video
        $allVideos.each(function () {

            $(this)
                .data('aspectRatio', this.height / this.width)

                // and remove the hard coded width/height
                .removeAttr('height')
                .removeAttr('width');

        });

        // When the window is resized
        $(window).resize(function () {

            var newWidth = $fluidEl.width();

            // Resize all videos according to their own aspect ratio
            $allVideos.each(function () {

                var $el = $(this);
                $el
                    .width(newWidth)
                    .height(newWidth * $el.data('aspectRatio'));

            });

        // Kick off one resize to fix all videos on page load
    }).resize();
});

/**
 * Sortin JSON resposne
 *
 * @param prop
 * @returns {Function}
 * @constructor
 */
function OrderListBy(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        }
        else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
